<template>
  <el-dialog
    custom-class="detailDialog"
    :close-on-click-modal="false"
    center
    width="1200px"
    title="检验检测报告公示"
    :visible.sync="visible"
  >
    <el-form ref="form" v-model="formData" label-width="160px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="机构名称：">
            <div>{{ formData.companyName }}</div>
          </el-form-item>
          <el-form-item label="CMA资质号：">
            <div>{{ formData.cmaQualification }}</div>
          </el-form-item>
          <el-form-item label="安全生产资质号：">
            <div>{{ formData.safetyProdQualification }}</div>
          </el-form-item>
          <el-form-item label="CNAS认可号：">
            <div>{{ formData.cnasRecognition }}</div>
          </el-form-item>
          <el-form-item label="报告编号：">
            <div>{{ formData.reportNo }}</div>
          </el-form-item>
          <el-form-item label="样品名称：">
            <div>{{ formData.sampleName }}</div>
          </el-form-item>
          <el-form-item label="规格型号：">
            <div>{{ formData.specModel }}</div>
          </el-form-item>
          <el-form-item label="委托人(单位)：">
            <div>{{ formData.wtOrgName }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="检验检测类别：">
            <div>{{ formData.inspectionTestingType }}</div>
          </el-form-item>
          <el-form-item label="报告发布日期：">
            <div>{{ formData.reportReleaseDate }}</div>
          </el-form-item>
          <el-form-item label="检验检测开始日期：">
            <div>{{ formData.checkStartDate }}</div>
          </el-form-item>
          <el-form-item label="检验检测结束日期：">
            <div>{{ formData.checkEndDate }}</div>
          </el-form-item>
          <el-form-item label="检验检测地点：">
            <div>{{ formData.checkPlace }}</div>
          </el-form-item>
          <el-form-item label="报告结论：">
            <div>{{ formData.conclusion }}</div>
          </el-form-item>
          <el-form-item label="所属行业：">
            <div>{{ formData.industry }}</div>
          </el-form-item>
          <el-form-item label="项目组长：">
            <div>{{ formData.projectLeader }}</div>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="勘验图像：" style="line-height: 26px;">
            <p style="line-height: 22px;color: #808080;" v-if="!formData.pictures || !formData.pictures.length">暂无图像</p>
            <el-image
              v-show="formData.pictures && formData.pictures.length>0"
              v-for="(v,i) in formData.pictures"
              :key="i"
              style="width: 104px;height: 104px;margin-right: 8px;border-radius: 8px;"
              :src="v.picReqUrl"
              :preview-src-list="pictures"
            ></el-image>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  data(){
    return{
      visible:false,
      formData:{
        pictures:[]
      }
    }
  },
  computed: {
    pictures() {
      let pics = this.formData.pictures.map(v => v.picReqUrl);
      return pics;
    }
  }
};
</script>
