<template>
  <div class="start">
   <p style="font-size: 24px;font-weight: 800;color: #333;text-align: center;">报告查询系统</p>
   <el-tabs @tab-click="tabClick" v-model="activeName">
    <el-tab-pane label="报告编号" name="first"></el-tab-pane>
    <el-tab-pane label="委托人(单位)" name="second"></el-tab-pane>
  </el-tabs>
  <div style="width:312px;margin:0 auto;">
    <el-input class="searchInput1" v-model="search.input1" :placeholder="activeName==='first'?'输入报告编号':'输入委托人(单位)'">
       <span slot="prefix" style="display:inline-block;"></span>
    </el-input>
    <el-input class="searchInput2" v-model="search.input2" placeholder="输入验证码">
       <span slot="prefix" style="display:inline-block;"></span>
    </el-input>
    <div class="code" @click="getCode">{{vcode}}</div>
    <p class="tc goSearch" @click="goSearch">立即查询</p>
  </div>
  
  <Dialog ref="visible"></Dialog>

  <!-- style="filter: grayscale(1);"  -->
  <el-dialog append-to-body custom-class="warringDialog" :close-on-click-modal="false" width="584px" :visible.sync="visible1">
    <div class="clearfix" style="height: 132px;">
      <p class="fl warringBg"></p>
      <div class="fl" style="width:calc(100% - 104px);padding-left:16px;">
        <p style="font-size: 24px;font-weight: bold;margin-bottom: 10px;">{{warringInfo.title}}</p>
        <p style="font-size: 14px;color:666;">{{warringInfo.info}}</p>
      </div>
    </div>
    <p class="tr btns">
      <span style="color: #666;border: 1px solid #E6E6E6;margin-right:16px;" @click="visible1 = false;">取消</span>
      <span style="background: #007BC7;color: #FFF;" @click="visible1 = false;">确认</span>
    </p>
  </el-dialog>
  </div>
</template>
<script>
import Dialog from './detail_report.vue'
export default {
  components:{
    Dialog
  },
  data(){
    return{
      activeName: 'first',
      search:{
        input1:'',
        input2:''
      },
      warringInfo:{
        title:'',
        info:''
      },
      visible1:false,
      vcode:''
    }
  },
  mounted(){
    this.getCode();
  },
  methods:{
    getCode(){
      this.$fetch(this.$requestUrl+"qingzhi-admin/web/vcode",{}).then(res => {
        if(res.msg != 'success'){
          this.$message.error(res.msg);
          return
        }
        this.vcode = res.text;
      })
    },
    tabClick(){
      this.search = {
        input1:'',
        input2:''
      }
    },
    goSearch(){
      if(!this.search.input1){
        this.warringInfo = {
          title:this.activeName === 'second' ? "委托人(单位)为空" : "报告编号为空",
          info:this.activeName === 'second' ? "委托人(单位)：请输入正确委托人(单位)":"报告编号：请输入正确报告编号"
        }
        this.visible1 = true;
        return
      }
      if (!this.search.input2){
        this.$message.error("请输入验证码");
        return
      }
      if(this.search.input2 != this.vcode){
        this.$message.error("验证码不正确");
        return
      }
      if(this.activeName === 'second'){
        this.$router.push("/listReport?key="+this.search.input1);
        return
      }
      this.$post(`${this.$requestUrl}qingzhi-admin/web/getByReportNo`,{reportNo: this.search.input1}).then(res => {
        this.getCode();
        this.search.input2 = "";
        if(res.code===500){
          this.$message.error(res.msg);
          return
        }
        if(res.report===null){
          this.warringInfo = {
          title:"没有查到证书",
          info:"报告编号：未查询到信息，请核查信息后再次查询。如有疑问请进一步联系相关业务人员，谢谢！"
        }
        this.visible1 = true;
        return
        }
        
        this.$refs.visible.visible = true;
        this.$refs.visible.formData = res.report;
      })
    }
  }
}
</script>
<style scope>
.content{
  height:calc(100% - 256px);
  min-height: 699px;
}
</style>

